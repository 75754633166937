import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Nav from './components/Nav';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';


const App = () => {
    return (
        <>
        <Nav />
        <div className="container" data-spy="scroll" data-target="#navbar-main" data-offset="0">
            <div id="about">
                <About />
            </div>
            <div id="projects">
                <Projects />
            </div>
            <div className="rounded" id="contact">
                <Contact />
            </div>
        </div>
        <br />
        <Footer />
        </>
    );
}

export default App;
