import React from 'react';
import i18n from '../i18n';


const Footer = () => {

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }
    
    return <>
        <div className="bg-dark">
            <ul className="nav justify-content-end">
                <li className="nav-item">
                    <a className="a-custom-projects nav-link" href="/#" onClick={() => changeLanguage('es')}>[español]</a>
                </li>
                <li className="nav-item">
                    <a className="a-custom-projects nav-link" href="/#" onClick={() => changeLanguage('en')}>[english]</a>
                </li>
            </ul>
        </div>
    </>
}

export default Footer;