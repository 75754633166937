import React from 'react';
import { withNamespaces } from 'react-i18next';
import { FaGitlab } from 'react-icons/fa';

const Projects = ({ t }) => {

    return <>
        <h4 className="project_title d-flex justify-content-center">{t('personal_projects_title')}</h4>
        <div className="row">
            <br />
            <div className="col-sm-4">
                <h5 className="d-flex justify-content-center">{t('pequena_lib_title')}</h5>
                <div>
                    <a className="a-custom-projects" href="https://gitlab.com/j_m_b/libreria_peq">[<FaGitlab /> Repo]</a>
                </div>
                <p>
                    {t('pequena_lib_text')}
                </p>
            </div>
            <div className="col-sm-4">
                <h5 className="d-flex justify-content-center">{t('tecnholistyc_title')}</h5>
                <div>
                    <a className="a-custom-projects" href="https://gitlab.com/j_m_b/tecnholistyc">[<FaGitlab /> Repo]</a>
                </div>
                <p>
                    {t('tecnholistyc_text')}
                </p>
            </div>
            <div className="col-sm-4">
                <h5 className="d-flex justify-content-center">{t('actyvadmon_title')}</h5>
                <div>
                    <a className="a-custom-projects" href="https://gitlab.com/j_m_b/front-actyvadmon">[<FaGitlab /> Repo - front end]</a>
                </div>
                <div>
                    <a className="a-custom-projects" href="https://gitlab.com/j_m_b/back-actyvadmon">[<FaGitlab /> Repo - back-end]</a>
                </div>
                <p>
                    {t('actyvadmon_text')}
                </p>
            </div>
        </div>
    </>;
}

export default withNamespaces()(Projects);