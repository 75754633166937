import React from 'react';
import { withNamespaces } from 'react-i18next';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaGitlab } from 'react-icons/fa';



const Contact = ({t}) => {

    return <>
        <div className="text-body">
            <h4 className="d-flex justify-content-center contact_title">{t('nav_contact')}</h4>
            <p><FaLinkedinIn /> Linkedin: <a className="a-custom-contact" href="https://www.linkedin.com/in/jonathan-mb/">linkedin.com/in/jonathan-mb/</a></p>
            <p><FaGitlab /> Gitlab: <a className="a-custom-contact" href="https://gitlab.com/j_m_b">gitlab.com/j_m_b/</a></p>
        </div>
    </>
}

export default withNamespaces()(Contact);
