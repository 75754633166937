import React from 'react';
import profile_pic from '../assets/images/2.png';
import { withNamespaces } from 'react-i18next';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaGitlab } from 'react-icons/fa';
import linux from  '../assets/icons/linux.svg';
import aws from  '../assets/icons/aws.svg';
import docker from  '../assets/icons/docker.svg';
import django from  '../assets/icons/django.svg';
import python from  '../assets/icons/python.svg';
import nodejs from '../assets/icons/nodejs.svg';
import expressjs from '../assets/icons/expressjs.svg';
import reactjs from '../assets/icons/reactjs.svg';
import laravel from  '../assets/icons/laravel.svg';
import php from  '../assets/icons/php.svg';
import js from  '../assets/icons/js.svg';
import html5 from  '../assets/icons/html5.svg';
import css3 from  '../assets/icons/css3.svg';
import bootstrap from  '../assets/icons/bootstrap.svg';
import apache from  '../assets/icons/apache.svg';
import nginx from  '../assets/icons/nginx.svg';
import git from  '../assets/icons/git.svg';
import gitlab from  '../assets/icons/gitlab.svg';
import mysql from  '../assets/icons/mysql.svg';
import sqlite from  '../assets/icons/sqlite.svg';
import postgresql from  '../assets/icons/postgresql.svg';
import mongodb from '../assets/icons/mongodb.svg';



const About = ({ t }) => {

    const logoIcons = [
        {'name':'linux', 'location': linux},
        {'name':'aws', 'location': aws},
        {'name':'docker', 'location': docker},
        {'name':'django', 'location': django},
        {'name':'python', 'location': python},
        {'name':'nodejs', 'location': nodejs},
        {'name':'expressjs', 'location': expressjs},
        {'name':'reactjs', 'location': reactjs},
        {'name':'laravel', 'location': laravel},
        {'name':'php', 'location': php},
        {'name':'js', 'location': js},
        {'name':'html5', 'location': html5},
        {'name':'css3', 'location': css3},
        {'name':'bootstrap', 'location': bootstrap},
        {'name':'apache', 'location': apache},
        {'name':'nginx', 'location': nginx},
        {'name':'git', 'location': git},
        {'name':'gitlab', 'location': gitlab},
        {'name':'mysql', 'location': mysql},
        {'name':'sqlite', 'location': sqlite},
        {'name':'postgresql', 'location': postgresql},
        {'name':'mongodb', 'location': mongodb},
    ]

    return <>
        <div>
            <h5 >
                {t('welcome')}
            </h5>
        </div>
        <div >
            <h5>
                {t('about_1')}
            </h5>
        </div>
        <br/>
        <div className="row">    
            <div className="col-sm-3">
                <img className="rounded" width="100%" src={profile_pic} alt="Not found"/>
                <div className="d-flex justify-content-center bg-dark">
                    <a className="btn btn-linkedin" href="https://www.linkedin.com/in/jonathan-mb/">
                        <FaLinkedinIn size="2rem" />
                    </a>
                    <a className="btn btn-gitlab" href="https://gitlab.com/j_m_b">
                        <FaGitlab size="2rem" />
                    </a>
                </div>
            </div>
            <div className="col-sm-9 ">
                <div className=" bg-light rounded d-flex p-3">
                    <div className="">
                        <h4 className="text-body d-flex justify-content-center">
                            { t('skills_title') }
                        </h4>
                        { logoIcons.map(logo => (
                            <img 
                                key={logo.name} 
                                src={logo.location} 
                                alt="#" width="64px" 
                                data-container="body" 
                                data-toggle="popover" 
                                data-placement="top" 
                                data-content={logo.name}
                                style={{padding: '0.5rem'}}
                            />
                        ))}
                    </div>
                </div>
                
                <div className="bg-xp rounded">
                    <h4 className="d-flex justify-content-center">{t('experienice_title')}</h4>
                    <div className="xp-text">
                        <h6>
                            {t('job_hacku')}
                        </h6>
                        <p>
                            {t('job_hacku_duration')}
                        </p>
                        <div>
                            <ul>
                                <li>
                                    {t('job_hacku_task_1')}
                                </li>
                                <li>
                                    {t('job_hacku_task_2')}
                                </li>
                                <li>
                                    {t('job_hacku_task_3')}
                                </li>
                                <li>
                                    {t('job_hacku_task_4')}
                                </li>
                                <li>
                                    {t('job_hacku_task_5')}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default withNamespaces()(About);
