import React from 'react';
// the hoc
import i18n from '../i18n';
import { withNamespaces } from 'react-i18next';



import nice_logo from '../assets/icons/nice_logo4.svg';
import en_flag from '../assets/icons/uk.svg';
import es_flag from '../assets/icons/spain.svg';


const Nav = ({ t }) => {

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    return (
        <nav id="navbar-main" className="navbar fixed-top navbar-expand-md ">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon">&#9776;</span>
            </button>
            <div className="d-md-flex d-block flex-row mx-md-auto mx-0">
                <a className="navbar-brand" href="#!"><img width="36px" src={nice_logo} alt=""/></a>
                <div className="collapse navbar-collapse " id="navbarNav">
                    <ul className="nav">
                        <li className="nav-item">
                            <a className="a-custom nav-link" href="#about">{t('nav_about')}</a>
                        </li>
                        <li className="nav-item">
                            <a className="a-custom nav-link" href="#projects">{t('nav_projects')}</a>
                        </li>
                        <li className="nav-item">
                            <a className="a-custom nav-link" href="#contact">{t('nav_contact')}</a>
                        </li>
                        <li className="nav-item dropdown">
                            <button className="btn a-custom nav-link dropdown-toggle bg-dark rounded" id="navbarDropdownLenguaje" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Lenguaje
                            </button>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownLenguaje">
                                <button className="btn dropdown-item" onClick={() => changeLanguage('en')}> 
                                    <img width="24px" src={en_flag} alt=""/> English
                                </button>
                                <button className="btn dropdown-item" onClick={() => changeLanguage('es')}> 
                                    <img width="24px" src={es_flag} alt=""/> Español
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default withNamespaces()(Nav);

